import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';

// A custom theme for this app
export const COLORS = {
  darkerYellow: '#8B6914',
  darkYellow: '#F4B400',
  lightYellow: '#F8D66D',
  transparentYellow: 'rgba(255, 223, 128, 0.2)',

  darkerGreen: '#4C675E',
  darkGreen: '#006400',
  darkGreen2: '#008b00',
  lightGreen: '#AFD4AB',
  transparentGreen: 'rgba(144, 238, 144, 0.2)',

  darkerRed: '#8B0000',
  darkRed: '#cc0000',
  darkRed2: '#e00000',
  lightRed: '#E08080',
  lighterRed: '#ecb1b1',
  transparentRed: 'rgba(255, 99, 99, 0.2)',

  darkGray: '#666666',
  mediumDarkGray: '#808080',
  mediumGray: '#ccc',
  lightGray: '#E0E0E0',
  lighterGray: '#f5f5f5',
  hoverGray: 'rgba(0, 0, 0, 0.04)',
  borderGray: '#EEF1F2',

  lightBlue: '#767AE5',
  darkBlue: '#565BDF',
  darkerBlue: '#2125AB',

  black: '#2E2C3C',
};

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF',
        },
        columnHeader: {
          backgroundColor: COLORS.lighterGray,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          color: 'black',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: COLORS.darkBlue,
            backgroundColor: 'white',
          },
          '&.Mui-selected:hover': {
            backgroundColor: COLORS.transparentGreen,
          },
          '&:hover': {
            backgroundColor: COLORS.transparentGreen,
          },
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        childLeaving: {
          color: COLORS.darkGreen,
        },
        ripple: {
          color: COLORS.darkGreen,
        },
        ripplePulsate: {
          color: COLORS.darkGreen,
        },
        rippleVisible: {
          color: COLORS.darkGreen,
        },
        child: {
          color: COLORS.darkGreen,
        },
        childPulsate: {
          color: COLORS.darkGreen,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        bar: {
          '&.dark-yellow-bar': { backgroundColor: COLORS.darkYellow },
          '&.dark-green-bar': { backgroundColor: COLORS.darkGreen },
          '&.dark-gray-bar': { backgroundColor: COLORS.darkGray },
          '&.light-gray-bar': { backgroundColor: COLORS.lightGray },
        },
        root: {
          '&.dark-yellow-root': { backgroundColor: COLORS.darkYellow },
          '&.light-gray-root': { backgroundColor: COLORS.lightGray },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          '&.trend-increasing': {
            border: 0,
            color: COLORS.darkGreen,
            '& .MuiChip-icon': {
              color: COLORS.darkGreen,
              fontSize: '1rem',
            },
          },
          '&.trend-increasing-swapped': {
            border: `1px solid ${COLORS.darkRed}`,
            color: COLORS.darkRed,
            '& .MuiChip-icon': {
              color: COLORS.darkRed,
              fontSize: '1rem',
            },
          },
          '&.trend-decreasing': {
            border: 0,
            color: COLORS.darkRed,
            '& .MuiChip-icon': {
              color: COLORS.darkRed,
              fontSize: '1rem',
            },
          },
          '&.trend-decreasing-swapped': {
            border: `1px solid ${COLORS.darkGreen}`,
            color: COLORS.darkGreen,
            '& .MuiChip-icon': {
              color: COLORS.darkGreen,
              fontSize: '1rem',
            },
          },
          '&.score-high': {
            backgroundColor: COLORS.transparentGreen,
            borderColor: COLORS.darkGreen,
          },
          '&.score-medium': {
            backgroundColor: COLORS.transparentYellow,
            borderColor: COLORS.darkYellow,
          },
          '&.score-low': {
            backgroundColor: COLORS.transparentRed,
            borderColor: COLORS.darkRed,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '&.info-icon': {
            fontSize: 'inherit',
            color: COLORS.darkBlue,
            cursor: 'pointer',
            position: 'relative',
            top: '0.125em',
            '&:hover': {
              color: COLORS.darkerBlue,
            },
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: 1,
        },
      },
    },
  },

  // https://mui.com/material-ui/customization/palette/
  palette: {
    primary: {
      main: COLORS.darkBlue,
    },
    secondary: {
      main: COLORS.lightBlue,
    },
    error: {
      main: red.A400,
    },
    text: {
      primary: COLORS.black,
    },
  },
});

export default theme;
